import React from 'react';
import {withTranslation} from "react-i18next";
// import "./stylesheet.scss";


class Footer extends React.Component {
    render() {
        const {t} = this.props;

        return (
            <div className="footer">
                <nav>
                    <a href="https://www.jentautolease.nl/privacyverklaring/">{t('footer.privacyStatement')}</a>
                    <span>-</span>
                    <a href="https://www.jentautolease.nl/disclaimer/">{t('footer.disclaimer')}</a>
                    <span>-</span>
                    <a href="https://www.jentautolease.nl/algemene-voorwaarden/">{t('footer.termsAndConditions')}</a>
                    <span>-</span>
                    <a href="https://www.jentautolease.nl/cookies/">{t('footer.cookies')}</a>
                </nav>
            </div>
        );
    }
}

export default withTranslation()(Footer);