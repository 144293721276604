import React from 'react';
// import './stylesheet.scss';
import {Spinner} from 'react-bootstrap';
import {withTranslation} from "react-i18next";

class LoadingIndicator extends React.Component {
    render() {
        const {t} = this.props;

        return <Spinner size="xs" animation="border" variant="primary" role="status">
            <span className="sr-only">{t('common.loading')}</span>
        </Spinner>
    }
}

export default withTranslation()(LoadingIndicator);